@import "../../utils/includes.scss";

// ========
// Fonts
// ========

$fontFamily: 'VWText', sans-serif;
$boldFontFamily: 'VWText-Bold', sans-serif;
$boldFontWeight: normal;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

// ========
// Colors
// ========

$textColor: #000000;
$linkColor: #00B0F0;
$labelColor: #96A3A8;
$formColor: #000000;
$utilColor: #00B0F0;
$priceColor: #00B0F0;
$lightColor: #96A3A8;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #009640;
$warningColor: #00B0F0;
$errorColor: #E4022C;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #00B0F0;
$colorBtnAlpha: #001E50;
$bgBtnBeta: #FFD103;
$colorBtnBeta: #fff;
$colorBtnOutlineAlpha: #6A767D;
$colorBtnOutlineBeta: #00B0F0;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 30px;

$borderRadiusForm: 5px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #ffffff;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #ffffff;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: #00B0F0;

$garageBackground: #ffffff;
$garageTextColor: #000000;
$garageCarNoteColor: #ffffff;

$bgTopbarLogin: #ffffff;
$colorTopbarLogin: #001E50;

$progressBarTitleBG: #001E50;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #ffffff;
$progressBarTitleCloseColor: #001E50;
$progressBarCloseButtonBG: #001E50;
$progressBarCloseButtonColor: #ffffff;

$paymentCalculatorClosedBG: #001E50;
$paymentCalculatorClosedColor: #DFE4E8;
$paymentCalculatorPanelBG: transparent;
$paymentCalculatorTermWrapperBG: #00437A;
$paymentCalculatorTermWrapperColor: #00B0F0;
$paymentCalculatorTermSelectedWrapperBG: #000000;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #000000;
$paymentCalculatorTermColor: #DFE4E8;

// ========
// Styles
// ========

@include breakpoint(mobile){
    .widget-sr{
        &.makes__volkswagen{
            .vehicle-tile-rebate-single{
                background: #000000;
            }
        }
    }
}

.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__volkswagen{
        font-family: $fontFamily;
        font-size: 14px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        #loginWidget{
            border-bottom: 2px solid #001E50;
        }
        .has-warning-background {
            background-color: $warningColor;
        }
        .TransactionDetails--wrapper:before{
            top: 2px;
        }
        .sr-button-1{
            transition: all 0.2s ease-in-out !important;
            color: #ffffff !important;
            background: #001E50 !important;
            border: 2px solid #001E50!important;
            box-shadow: none !important;
            &:hover{
                transition: all 0.2s ease-in-out !important;
                opacity: 1 !important;
                color: #001E50 !important;
                background: transparent !important;
                border: 2px solid #001E50 !important;
                box-shadow: none !important;
                icon.icon-refresh{
                    transition: all 0.2s ease-in-out !important;
                    opacity: 1 !important;
                    color: #001E50 !important;
                    &::before{
                        transition: all 0.2s ease-in-out !important;
                    }
                } 
            }
            icon.icon-refresh{
                transition: all 0.2s ease-in-out !important;
                opacity: 1 !important;
                color: #ffffff !important;
                &::before{
                    transition: all 0.2s ease-in-out !important;
                }
            }
        }
        .sr-button-2{
            transition: all 0.2s ease-in-out !important;
            color: #001E50 !important;
            background: transparent !important;
            border: 2px solid #001E50 !important;
            box-shadow: none !important;
            &:hover{
                transition: all 0.2s ease-in-out !important;
                opacity: 1 !important;
                color: #ffffff !important;
                background: #001E50 !important;
                border: 2px solid #001E50 !important;
                box-shadow: none !important;
            }
        }
        .sr-button-outline-2{
            transition: all 0.2s ease-in-out !important;
            color: #001E50 !important;
            border: 2px solid #001E50 !important;
            &:hover{
                opacity: 1 !important;
                color: #ffffff !important;
                background: #001E50 !important;
            }
        }
        .frequencies-panel-wrapper.sr-paymentCalculator-Panelbackground{
            background-color: #001E50;
            background: transparent linear-gradient(180deg, rgba(0, 30, 80, 1) 0%, rgba(0, 67, 122, 1) 100%) 0% 0% no-repeat padding-box;
            .InputNumber [type='text']{
                border: 1px solid #00B0F0 !important;
                padding: 12px 0 !important;
            }
            .InputNumber input{
                border-color: #00B0F0;
            }
            .InputNumber--add, .InputNumber--minus{
                border-color: #00B0F0;
            }
            .sr-paymentCalculator-dropdown .is-focused .Select-control,
            .sr-paymentCalculator-dropdown .Select-control,
            .sr-paymentCalculator-dropdown .Select-option{
                background-color: transparent!important;
                background: none!important;
                border: 1px solid #00B0F0 !important; 
            }
            .sr-paymentCalculator-dropdown .Select-menu-outer{
                background-color: #001E50!important;
                background: #001E50!important;
                border: 1px solid #00B0F0 !important;
                border-left: none !important;
                border-right: none !important;  
                border-top: none !important;
            }
            .is-light{
                color: #fff;
            }
        }
        .frequencies-panel-wrapper>section:first-child{
            border-top: 1px solid #00B0F0;
        }
        .payment-calculator-wrapper{
            .is-light{
                color: #fff;
            }
        }
        #calculator-toggle-button{
            color: #001e50!important;
            background: #00b0f0 !important;
            border: none !important;
            transition: all 0s !important;
        }
        .calculator-done{
            border-top: 1px solid #fff !important;
            .sr-button-1{
                transition: all 0.2s ease-in-out !important;
                box-shadow: none;
                color: #ffffff!important;
                background: transparent !important;
                border: 2px solid #ffffff !important;
                &:hover{
                    transition: all 0.2s ease-in-out !important;
                    opacity: 1;
                    border: 2px solid #00b0f0 !important;
                    color: #00b0f0 !important;
                }
            }
        }
        .purchase-methods-tab .sr-calculator-tabs{
            background-color: transparent;
        }
        .calculatorColumn{
            border-right-color: #fff;
        }
        .calculator-cash-down,
        .calculator-tradeIn,
        .new-customize-payment,
        .section-distance{
            border-bottom-color: #fff;
        }
        .purchase-methods-cash .purchase-detail-line.price_total{
            background-color: transparent;
        }
        #open-calculator{
            color: #001E50 !important;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            background-color: $paymentCalculatorTermBG !important;
        }
        .TransactionSummaryUnit--single:nth-child(1){
            background-color: #ffffff;
            border-bottom-color: rgba(0, 30, 80, 0.25) !important;
        }
        .TransactionSummaryUnit--single:nth-child(2){
            background-color: #DFE4E8;
            border-bottom-color: rgba(0, 30, 80, 0.5) !important;
        }
        .TransactionSummaryUnit--single:nth-child(3){
            background-color: #C2CACF;
            border-bottom-color: #001E50 !important;
        }
        .btn-register.sr-button-1{
            background: #001E50 !important;
            color: #ffffff !important;
            border: none !important;
            &:hover{
                border: none !important;
            }
            span.label{
                cursor: pointer;
            }
        }
        .btn-register.sr-button-1:hover{
            opacity: 1;
            color: #001E50 !important;
            background: #00B0F0 !important;
        }
        .method-button-payment-bar button:after{
            background-color: #001E50 !important;
        }
        .style-color__button.selected,
        .listing-used-filters .rc-slider .rc-slider-handle{
            border-color: #00b0f0;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #00b0f0;
        }
        .selection-year-bloc:hover{
            border-color: #96A3A8;
        }
        .selection-year-bloc:hover .selection-year-bloc__selectButton{
            background-color: #96A3A8;
        }
        .sr-button-outline-2.is-hover,
        .sr-button-outline-2.is-hover:hover{
            background: #001E50 !important;
            color: #ffffff !important;
            border-color: #001E50 !important;
        }
        .vdp-used-form .cta-visit.sr-button-1{
            background: transparent !important;
            color: #ffffff !important;
            border-color: #ffffff !important;
            text-transform: none !important;
            &:hover{
                background: transparent !important;
                color: $linkColor !important;
                border-color: $linkColor !important;
                text-transform: none !important;
            }
        }

        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper{
            .TransactionSummaryUnit--single.is-validate{
                border-bottom-color: #001E50!important;
            }
        }
        .TransactionSummaryUnit--single{
            .validFlag:before{
                background-color: transparent;
                background-image: -webkit-linear-gradient(304deg,transparent 50%,#001E50 55%) !important;
            }
        }
        .TransactionProgressBar--progression{
            background-color: #001E50 !important;
        }
        .confirm_deposit--icon,
        .confirm_financing--icon{
            border: 1px solid #001E50 !important;
            .icon,.icon-Crochet{
                color: #001E50 !important;
            }
        }
        .confirm_deposit--title,
        .confirm_financing--title{
            color: #001E50;
        }
        .section-checkout-header__progressBar-stage.validated{
            .picto-showroom:first-child{
                color: #001E50;
            }
        }
        .Appointment__content .appointment__steps--single.is-validated {
            .icon-calendar{
                color: #001E50 !important;
            }
        }
        .doneLabel {
            color: #001E50 !important;
            .icon-Crochet{
                color: #001E50 !important;
            }
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: auto;
        }

        &.trade-is-on-top{
            .listing-used-car-search>div:last-child {
                display: flex;
                flex-direction: column;
                #instant-estimate-bar-wrapper{
                    order: 1;
                    margin-top: 0 !important;
                }
                .listing-tiles{
                    order: 2;
                }
                .listing-used-button-loading{
                    order: 3;
                }
            }
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(desktop){
    .widget-sr{
        &.makes__volkswagen{
            .payment-calculator-wrapper{
                z-index: 9;
            }
        }
    }
}